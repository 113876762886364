<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="flex">
    <div class="w-1/5">
      <sidebar/>
    </div>
    <div class="w-4/5 ml-auto px-4">
      <div v-if="$store.state.benefits.home_page">
        <p class="text-gray-600 font-semibold">{{loc.most_popular.toUpperCase()}}</p>
        <benefit-list :benefits="$store.state.benefits.home.popular" v-if="$store.state.benefits.home"/>
        <p class="text-gray-600 font-semibold">{{loc.recently_added.toUpperCase()}}</p>
        <benefit-list :benefits="$store.state.benefits.home.last" v-if="$store.state.benefits.home"/>
      </div>
      <div v-else>
        <p v-if="benefits_type == 'flexible'" class="text-gray-600 mb-6 text-sm">{{loc.flexible_desc}}</p>
        <additional-filters v-if="benefits_type!='fixed'"/>
        <benefit-list :benefits="$store.state.benefits.benefits.results" v-if="$store.state.benefits.benefits"/>
      </div>
    </div>
<!--    <ul v-else id="content-space" role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">-->
<!--      <li v-for="person in people" :key="person.email" class="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">-->
<!--        <div class="w-full items-center justify-between p-6 space-x-6">-->
<!--          <img cl src="https://platform.hob.rs/media/uploads/benefits/bbc866ab-67e0-441a-83a0-50824612e133.jpg">-->
<!--          <div class="flex-1 truncate">-->
<!--            <div class="flex items-center space-x-3">-->
<!--              <h3 class="text-gray-900 text-sm font-medium truncate">Benefit</h3>-->
<!--            </div>-->
<!--            <p class="mt-1 text-gray-500 text-sm truncate">Benefit</p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </li>-->
<!--    </ul>-->
  </div>
</template>

<script>
import { MailIcon, PhoneIcon } from '@heroicons/vue/solid'
import {mapActions, mapState, mapGetters} from "vuex";
import benefitList from "@/components/benefitList";
import AdditionalFilters from "@/components/additionalFilters";
import sidebar from "@/components/ui/sidebar";

export default {
  components: {
    AdditionalFilters,
    MailIcon,
    PhoneIcon,
    benefitList,
    sidebar
  },
  methods: {
    ...mapActions('benefits', ['fetchBenefits', 'fetchHome', 'load_mode']),
  },
  mounted() {
    this.$store.dispatch('filters/fetch_categories')
    if (this.$store.state.benefits.home_page) {
      this.fetchHome()
    }
    const _this = this
    window.onscroll = () => {
      let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight > document.documentElement.offsetHeight - 10;
      if (bottomOfWindow) {
        if (_this.$route.path == '/' && _this.isAuth) {
          _this.load_mode()
        }
      }
    };
  },
  computed: {
    ...mapState('benefits', ['benefits_type']),
    ...mapGetters('auth', ['isAuth'])
  }
}
</script>