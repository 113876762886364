import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/about',
    name: 'About',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/About')
  },
  {
    path: '/about/',
    name: 'About',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/About')
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      requiresAuth: false
    },
    component: () => import('../views/Login')
  },
  {
    path: '/register',
    name: 'Register',
    meta: {
      requiresAuth: false
    },
    component: () => import('../views/Register')
  },
  {
    path: '/admin-panel',
    name: 'AdminPanel',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/AdminPanel')
  },
  {
    path: '/corporate-panel',
    name: 'CorporatePanel',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/CorporatePanel')
  },
  {
    path: '/course/:id',
    name: 'CoursePreview',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/CoursePreview')
  },
  {
    path: '/package/:id',
    name: 'PackagePreview',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/Package')
  },
  {
    path: '/cart/',
    name: 'CartPage',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/Cart')
  },
  {
    path: '/success-payment/',
    name: 'SuccessPayment',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/SuccessPayment')
  },
  {
    path: '/initial/',
    name: 'Initial',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/Initial')
  },
  {
    path: '/packages/',
    name: 'Packages',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/Packages')
  },
  {
    path: '/course/start/:id',
    name: 'Start',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/Start')
  },
  {
    path: '/success-certificate/',
    name: 'SuccessCertificate',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/SuccessCertificate')
  },
  {
    path: '/failed-certificate/:id',
    name: 'FailedCertificate',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/FailedCertificate')
  },
  {
    path: '/certificates/',
    name: 'Certificates',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/Certificates')
  },
  {
    path: '/account/',
    name: 'Account',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/Account')
  },
  {
    path: '/continuation/',
    name: 'Continuation',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/Continuation')
  },
  {
    path: '/faq/',
    name: 'Faq',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/Faq')
  },
  {
    path: '/contact/',
    name: 'Contact',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/Contact')
  },
  {
    path: '/confirm/:id',
    name: 'Confirm',
    meta: {
      requiresAuth: false
    },
    component: () => import('../views/Confirm')
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    meta: {
      requiresAuth: false
    },
    component: () => import('../views/ForgotPassword')
  },
  {
    path: '/reset-password/:id',
    name: 'ResetPassword',
    meta: {
      requiresAuth: false
    },
    component: () => import('../views/ResetPassword')
  },
  {
    path: '/profile',
    name: 'Profile',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/Profile')
  },
  {
    path: '/benefit/:id',
    name: 'Benefit',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/BenefitPreview')
  },
  {
    path: '/suggestion',
    name: 'Suggestion',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/Suggestion')
  },
  {
    path: '/transactions',
    name: 'Transactions',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/Transactions')
  },
  {
    path: '/reset_password_form/:id',
    name: 'ResetPasswordForm',
    meta: {
      requiresAuth: false
    },
    component: () => import('../views/ResetPasswordForm')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash, behavior: "smooth" };
    } else {
      window.scrollTo(0, 0);
    }
  }
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {


    if (!localStorage.getItem('token')) {
      next({
        path: '/login',
      })
    }
    else {
      if (!store.state.auth.user) {
        await store.dispatch('auth/checkAuth')
        await store.dispatch('auth/fetchUser')
      }
      if (to.name == 'AdminPanel') {
        if (!store.getters["auth/isAdmin"] && to.name == 'AdminPanel') {
          next({path: '/'})
        }
        next()
      } else
      if (to.name == 'CorporatePanel') {
        if (!store.state.auth.user.corporate_account && to.name == 'CorporatePanel') {
          next({path: '/'})
        }
        next()
      }
      next()
    }
  }
  else {
    next()
  }
})

export default router
