import {mapGetters, mapMutations} from "vuex"
import locale from "../locale/translates"

export default {
    data() {
        return {
            message: 'hello',
            foo: 'abc'
        }
    },
    computed: {
        ...mapGetters("auth", {
            language: "language"
        }),
        loc() {
            if (locale[this.language]) {
                return locale[this.language]
            }
            return ""
        }
    },
    methods: {
        ...mapMutations('ui', {
            set_error: 'set_error',
            set_success: 'set_success'
        }),
        monetary(value, currency_code) {
            // return "per"
            if (value === "") {
                return ""
            }
            let currency = 'RSD'
            if (currency_code) {
                currency = currency_code
            }
            const formated = new Intl.NumberFormat('en-DE', { style: 'currency', currency: currency == "KM" ? "BAM" : currency, maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(value)
            let currency_split = currency == "EUR" ? "€" : currency == "KM" ? "BAM" : currency;
            return formated.split(currency_split)[1] + ' ' + currency;
        },
        getBaseImg(images) {
            if (!images || !images.length) {
                return null
            }

            function compare( a, b ) {
                if ( a.position < b.position ){
                    return -1;
                }
                if ( a.position > b.position ){
                    return 1;
                }
                return 0;
            }

            return images.sort( compare )[0].image;
        }
    }
}