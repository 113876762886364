import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './index.css'
import axios from './config/http'
import VueAxios from 'vue-axios'
import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css'

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import '@/assets/css/fontawesome/css/font-awesome.min.css';
import locale from "@/mixins/locale"
import debounce from "@/config/debouncer"
// import monetary from "@/filters/monetary";
// createApp(App).mount('#app')

createApp(App)
    // .filter('monetary', monetary)
    .directive('debounce', (el,binding) => debounce(el,binding))
    .mixin(locale)
    .use(store)
    .use(router)
    .use(VueAxios, axios)
    .component('Datepicker', Datepicker)
    .component('QuillEditor', QuillEditor).mount('#app')
