<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0" @close="open = false">
      <div class="flex items-end justify-center  pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out dur<=ation-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen " aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="inline-block align-bottom bg-white rounded-lg pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-full sm:w-1/3 mt-10">
            <div class="overflow-y-auto modal-slot">
              <div class="rounded-l-lg bg-cover w-full h-64 bg-gray-400" :style="`background-image: url('${getBaseImg(benefit.pictures)}'); background-size: cover; background-position: center center; background-repeat: no-repeat;`"/>
              <div class="p-4">
                <p class="text-center text-xl font-semibold text-base-600">{{benefit.name}}</p>
                <div v-html="benefit.description" class="text-gray-600"></div>
              </div>
            </div>
<!--            <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense sm:w-1/2">-->
<!--              <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-base-600 text-base font-medium text-white hover:bg-base-700 focus:outline-none sm:col-start-2 sm:text-sm" @click="$emit('close')">-->
<!--                {{cancelText}}-->
<!--              </button>-->
<!--              <button v-if="!hide_submit" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:col-start-1 sm:text-sm" @click="$emit('submit')">-->
<!--                <div v-if="loading">-->
<!--                  <div style="border-top-color:transparent"-->
<!--                       class="w-6 h-6 border-4 border-blue-400 border-double rounded-full animate-spin"></div>-->
<!--                </div>-->
<!--                <span v-else>{{submitText}}</span>-->
<!--              </button>-->
<!--            </div>-->
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from 'vue'
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { CheckIcon, InformationCircleIcon } from '@heroicons/vue/outline'

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    CheckIcon,
  },
  props: {
    benefit: {
      type: Object
    },
    hide_submit: {
      type: Boolean,
      default: false
    },
    open: {
      type: Boolean,
      default: false
    },
    submitText: {
      type: String,
      default: 'Submit'
    },
    cancelText: {
      type: String,
      default: 'Cancel'
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    // open() {
    //   return false
    // }
  }
}
</script>

<style>
.modal-slot {
  height: calc(100vh - 250px);
}
</style>