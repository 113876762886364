<template>
  <div class="">
    <div v-for="provider in selected_category_providers" class="flex flex-col justify-start items-center p-2">
<!--      <div class="rounded-full bg-base-600 w-5 h-5 flex justify-center items-center text-white">{{provider.num_of_benefits}}</div>-->
      <div
          @click="toggle_selected_providers(provider.id)"
          :class="`flex cursor-pointer bg-gray-400 rounded-full h-14 ${selected_providers.includes(provider.id) ? 'border-4 shadow-xl shadow-base-700' : ''} border-base-600`">
        <img class="rounded-full h-14 w-14" v-if="provider.picture" :src="provider.picture">
        <div class="rounded-full bg-base-600 w-5 h-5 min-w-5 min-h-5 flex justify-center items-center text-white">{{provider.num_of_benefits}}</div>
      </div>
      <p class="text-sm text-center text-gray-600">{{provider.name}}</p>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
  name: "providersSelect",
  computed: {
    ...mapState('filters', ['selected_category_providers']),
    ...mapState('benefits', ['selected_providers'])
  },
  methods: {
    ...mapMutations('benefits', ['toggle_selected_providers'])
  }
}
</script>

<style scoped>
img {
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%
}
</style>