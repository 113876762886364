<template>
  <div class="bg-gray-100 min-h-screen max-w-screen">
    <SuccessNotification :show="$store.state.ui.success" :message="$store.state.ui.success_message"/>
    <ErrorNotification :show="$store.state.ui.error" :message="$store.state.ui.error_message"/>
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog as="div" class="fixed inset-0 flex z-40 md:hidden" @close="sidebarOpen = false">
        <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>
        <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
          <div class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
            <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button type="button" class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" @click="sidebarOpen = false">
                  <span class="sr-only">Close sidebar</span>
                  <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
            <new-logo class="w-10 h-10"/>
<!--            <div class="flex-shrink-0 flex items-center px-4">-->
<!--              <img class="h-8 w-auto" src="https://tailwindui.com/img/logos/workflow-logo-base-600-mark-gray-800-text.svg" alt="Workflow" />-->
<!--            </div>-->
<!--            <div class="mt-5 flex-1 h-0 overflow-y-auto">-->
<!--              <nav class="px-2 space-y-1">-->
<!--                <a v-for="item in navigation" :key="item.name" :href="item.href" :class="[item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group flex items-center px-2 py-2 text-base font-medium rounded-md']">-->
<!--                  <component :is="item.icon" :class="[item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500', 'mr-4 flex-shrink-0 h-6 w-6']" aria-hidden="true" />-->
<!--                  {{ item.name }}-->
<!--                </a>-->
<!--              </nav>-->
<!--            </div>-->
          </div>
        </TransitionChild>
        <div class="flex-shrink-0 w-14" aria-hidden="true">
          <!-- Dummy element to force sidebar to shrink to fit close icon -->
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
<!--    <sidebar/>-->
    <div class=" flex flex-col flex-1">
      <div class="sticky top-0 z-10 h-24 pt-10 md:pt-0 bg-white shadow">

        <div class="w-full mx-auto px-4 sm:px-6 md:px-8">

<!--          Prva linija-->
          <div class="w-full flex py-2">
<!--            <button type="button" class="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-base-500 md:hidden" @click="sidebarOpen = true">-->
<!--              <span class="sr-only">Open sidebar</span>-->
<!--              <MenuAlt2Icon class="h-6 w-6" aria-hidden="true" />-->
<!--            </button>-->
            <div class="flex justify-between w-full items-center">
<!--              <div @click="sidebar = true" class="rounded-lg bg-gray-100 p-1 border border-gray-200">-->
<!--                <FilterIcon class="text-gray-600 w-8 h-8"/>-->
<!--              </div>-->
              <div class="flex items-center space-x-4">
                <img @click="set_home_page(true); $router.push('/')" class="w-10 h-10" src="/logo.png">
                <p class="font-bold">House of Benefits</p>
              </div>
              <div class="flex justify-end items-center">

                <Menu id="header-items-space" as="div" class="mx-auto h-full">

                  <div class="flex items-center justify-end h-full space-x-4 font-semibold text-gray-500">

<!--                    <button @click="favorites_menu=true" class="bg-white rounded-full flex items-center text-gray-400 hover:text-gray-600 relative">-->
<!--                      <div v-if="$store.state.auth.user.favorite.length" class="flex align-items-center justify-center -top-1 -right-2 absolute bg-red-600 w-4 h-4 text-sm text-center text-white rounded-full align-middle">-->
<!--                        <p class="leading-none">{{$store.state.auth.user.favorite.length}}</p>-->
<!--                      </div>-->
<!--                      <HeartIcon class="h-8 w-8" aria-hidden="true" />-->
<!--                    </button>-->

<!--                    <button @click="cart_menu = true" class="bg-white rounded-full flex items-center text-gray-400 hover:text-gray-600 relative">-->
<!--                      <div v-if="$store.state.auth.user.cart.length" class="flex align-items-center justify-center -top-1 -right-2 absolute bg-base-600 w-4 h-4 text-sm text-center text-white rounded-full align-middle">-->
<!--                        <p class="leading-none">{{$store.state.auth.user.cart.length}}</p>-->
<!--                      </div>-->
<!--                      <ShoppingCartIcon class="h-8 w-8" aria-hidden="true" />-->
<!--                    </button>-->

                    <button @click="preview_notifications(); notifications_menu=true;" class="bg-white rounded-full flex items-center text-gray-400 hover:text-gray-600 relative">
                      <div v-if="$store.state.auth.user.new_notifications" class="flex align-items-center justify-center -top-1 -right-2 absolute bg-red-600 w-4 h-4 text-sm text-center text-white rounded-full align-middle">
                        <p class="leading-none">{{$store.state.auth.user.new_notifications}}</p>
                      </div>
                      <BellIcon class="h-8 w-8" aria-hidden="true" />
                    </button>



<!--                    <Menu as="div" class="relative inline-block text-left">-->
<!--                      <div>-->
<!--                        <MenuButton class="bg-white rounded-full flex items-center text-gray-400 hover:text-gray-600 relative">-->
<!--                          <div v-if="$store.state.auth.user.cart.length" class="flex align-items-center justify-center -top-1 -right-2 absolute bg-base-600 w-4 h-4 text-sm text-center text-white rounded-full align-middle">-->
<!--                            <p class="leading-none">{{$store.state.auth.user.cart.length}}</p>-->
<!--                          </div>-->
<!--                          <ShoppingCartIcon class="h-8 w-8" aria-hidden="true" />-->
<!--                        </MenuButton>-->
<!--                      </div>-->

<!--                      <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">-->
<!--                        <MenuItems class="origin-top-right absolute right-0 mt-2 w-80 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">-->
<!--                          <div class="py-1">-->
<!--                            <p class="text-center">{{loc.cart}}</p>-->

<!--                            <MenuItem v-slot="{ active }" v-for="item in $store.state.auth.user.cart">-->
<!--                              <div class="flex px-2 pt-2">-->
<!--                                <img v-if="item.benefit.pictures.length" :src="item.benefit.pictures[0].image" class="w-10 h-10 rounded-full">-->
<!--                                <div class="w-10 h-10 rounded-full bg-gray-600" v-else />-->
<!--                                <div class="px-4">-->
<!--                                  <p class="text-sm	">{{item.benefit.name}}</p>-->
<!--                                  <p class="text-base-600">{{monetary(item.benefit.price * (100 - item.benefit.discount) / 100, item.benefit.currency)}}</p>-->
<!--                                </div>-->
<!--                                <x-icon class="w-4 h-4 cursor-pointer  ml-auto" @click.stop="$store.dispatch('auth/toggleCart', item.benefit.id)"/>-->
<!--                              </div>-->
<!--                            </MenuItem>-->

<!--                            <div class="flex p-2">-->
<!--                              <button @click="$router.push('/cart')" type="button" class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-base-600 hover:bg-base-700 focus:outline-none ">{{loc.prev_cart}}</button>-->
<!--                              <p class="ml-auto">{{monetary($store.state.auth.user.cart.reduce((partialSum, a) => partialSum + Number(a.benefit.price) * (100 - a.benefit.discount) / 100, 0), $store.state.auth.user.currency)}}</p>-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </MenuItems>-->
<!--                      </transition>-->
<!--                    </Menu>-->


<!--                    <Menu as="div" class="relative inline-block text-left">-->
<!--                      <div>-->
<!--                        <MenuButton @click="preview_notifications" class="bg-white rounded-full flex items-center text-gray-400 hover:text-gray-600 relative">-->
<!--                          <div v-if="$store.state.auth.user.new_notifications" class="flex align-items-center justify-center -top-1 -right-2 absolute bg-red-600 w-4 h-4 text-sm text-center text-white rounded-full align-middle">-->
<!--                            <p class="leading-none">{{$store.state.auth.user.new_notifications}}</p>-->
<!--                          </div>-->
<!--                          <BellIcon class="h-8 w-8" aria-hidden="true" />-->
<!--                        </MenuButton>-->
<!--                      </div>-->

<!--                      <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">-->
<!--                        <MenuItems class="origin-top-right absolute right-0 mt-2 w-80 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">-->
<!--                          <div class="py-1 max-h-56 overflow-auto divide-y">-->

<!--                            <MenuItem v-slot="{ active }" v-for="item in $store.state.auth.user.notifications" class="divider-y">-->
<!--                              <div :class="`px-2 py-2 ${item.previewed ? 'hover:bg-gray-100' : 'bg-gray-200 hover:bg-gray-300'} cursor-pointer`">-->
<!--                                <p>{{item.text}}</p>-->
<!--                                <p class="text-xs text-base-500">{{item.date}}</p>-->
<!--                              </div>-->
<!--                            </MenuItem>-->
<!--                          </div>-->
<!--                        </MenuItems>-->
<!--                      </transition>-->
<!--                    </Menu>-->

                    <div @click="menu = true" class="">
                      <MenuAlt3Icon class="text-gray-600 w-8 h-8 text-base-600"/>
                    </div>

                  </div>

                </Menu>
              </div>
            </div>
          </div>

        </div>
      </div>

      <main class="flex-1">
        <div>
          <div class="mx-auto">
            <!-- Replace with your content -->
<!--            <div class="py-4">-->
<!--              <div class="border-4 border-dashed border-gray-200 rounded-lg h-96" />-->
<!--            </div>-->
            <slot/>
            <!-- /End replace -->
          </div>
        </div>
      </main>
    </div>
    <!--          Druga linija-->
    <div class="fixed bottom-0 bg-white w-full z-30">
      <div class="flex space-x-3 border-t py-2 pb-6 justify-around">
        <button @click="set_home_page(true)" type="button" :class="`text-2xl inline-flex items-center px-3.5 py-2 border border-transparent leading-4 font-medium  ${$store.state.benefits.home_page ? ' text-base-600' : ' text-gray-600'} focus:outline-none `">
          <i class="fa fa-home" aria-hidden="true"></i>
        </button>

        <button @click="set_benefits_type_check('flexible')" type="button" :class="`text-2xl inline-flex items-center px-3.5 py-2 border border-transparent leading-4 font-medium  ${benefits_type == 'flexible' && !$store.state.benefits.home_page ? 'text-base-600' : ' text-gray-600'} focus:outline-none `">
          <i class="fa fa-check-circle-o" aria-hidden="true"></i>
        </button>
        <button @click="set_benefits_type_check('fixed')" type="button" :class="`text-2xl inline-flex items-center px-3.5 py-2 border border-transparent leading-4 font-medium  ${benefits_type == 'fixed' && !$store.state.benefits.home_page ? ' text-base-600' : ' text-gray-600'} focus:outline-none `">
          <i class="fa fa-lock" aria-hidden="true"></i>
        </button>
        <button @click="set_benefits_type_check('special')" type="button" :class="` text-2xl inline-flex items-center px-3.5 py-2 border border-transparent leading-4 font-medium  ${benefits_type == 'special' && !$store.state.benefits.home_page ? ' text-base-600' : ' text-gray-600'} focus:outline-none `">
          <i class="fa fa-percent" aria-hidden="true"></i>
        </button>
        <!--            <button @click="set_benefits_type_check('team_building')" type="button" :class="`inline-flex items-center px-3.5 py-2 border border-transparent text-sm leading-4 font-medium  hover:text-white ${benefits_type == 'team_building' ? 'bg-base-600 text-white' : 'bg-base-transparent_500 text-base-600'} hover:bg-base-600 focus:outline-none `">{{ loc.team_building }}</button>-->
      </div>
    </div>
  </div>
  <transition
      name="custom-classes-transition"
      enter-active-class="animated tada"
      leave-active-class="animated bounceOutRight"
  >
    <loader v-if="$store.getters['ui/is_loading']"/>
  </transition>

  <mobile-modal :open="sidebar" @close="sidebar = false">
    <sidebar @close="sidebar = false"/>
  </mobile-modal>
  <mobile-modal :open="menu" @close="menu = false">
      <div class="flex flex-col justify-between p-5 bg-base-600 text-white rounded-xl shadow bg-blur z-40 in-menu font-semibold">
        <div v-if="$store.state.auth.user" class="cursor-pointer space-y-2">
          <div class="flex justify-end mb-4">
            <x-icon class="w-8 h-8 btn" @click="menu=false" />
          </div>
          <div>
            <div @click.stop="change_language" class="flex px-4 py-2 text-lg text-white   justify-between items-center">
              {{ $store.state.auth.user.language == "SR" ? "Language: " : "Jezik: " }}
              <img
                  v-if="$store.state.auth.user.language == 'EN'"
                  class="rounded-full ml-4"
                  style="height: 30px; width: 30px"
                  src="/flags/Serbia.png"
                  alt="serbian flag"
              />
              <img
                  v-if="$store.state.auth.user.language == 'SR'"
                  class="rounded-full ml-4"
                  style="height: 30px; width: 30px"
                  src="/flags/United-States.png"
                  alt="usa flag"
              />
            </div>
          </div>

          <div class="cursor-pointer text-left flex items-center justify-between">
            <div @click="$router.push('/cart'); menu=false;" class="block px-4 py-2 text-lg text-white w-full">{{ loc.cart }}</div>
            <div class="mr-4 w-7 h-7 rounded-full bg-base-500 flex justify-center text-center items-center">{{$store.state.auth.user.cart.length}}</div>
          </div>

          <div class="cursor-pointer text-left flex items-center justify-between">
            <div @click="favorites_menu = true; menu=false;" class="block px-4 py-2 text-lg text-white w-full">{{ loc.favorites }}</div>
            <div class="mr-4 w-7 h-7 rounded-full bg-red-600 flex justify-center text-center items-center">{{$store.state.auth.user.favorite.length}}</div>
          </div>

          <div class="cursor-pointer text-left">
            <div @click="$router.push('/profile'); menu=false;" class="block px-4 py-2 text-lg text-white   ">{{ loc.profile }}</div>
          </div>

          <div class="cursor-pointer text-left">
            <div @click="$router.push('/transactions'); menu=false;" class="block px-4 py-2 text-lg text-white   ">{{ loc.transactions }}</div>
          </div>

          <div class="cursor-pointer text-left">
            <div @click="$router.push('/suggestion'); menu=false;" class="block px-4 py-2 text-lg text-white   ">{{ loc.benefit_suggestion }}</div>
          </div>

          <div class="cursor-pointer text-left">
            <a class="block px-4 py-2 text-lg text-white" target="_blank" href="https://hob.fra1.cdn.digitaloceanspaces.com/UPUTSTVO%20ZA%20KORIS%CC%8CC%CC%81ENJE%20APLIKACIJE.pdf">{{loc.tutorial}}</a>
          </div>

          <div class="cursor-pointer text-left">
            <div @click="$router.push('/contact'); menu=false;" class="block px-4 py-2 text-lg text-white   ">{{ loc.contact }}</div>
          </div>
          </div>


        <div class="flex items-center justify-start cursor-pointer mt-auto">
          <logout-icon class="w-8 h-8 text-white" />
          <div @click="logout" class="block px-4 py-2 text-lg text-white   ">{{ loc.logout }}</div>
        </div>

      </div>
  </mobile-modal>
  <mobile-modal :open="favorites_menu" @close="favorites_menu = false">
      <div class="flex flex-col justify-between p-5 bg-gradient-to-r from-blur-1 via-blur-2 to-blur-1 rounded-xl shadow bg-blur z-40 in-menu">
        <div class="py-1">
          <div class="flex justify-end">
            <x-icon class="w-8 h-8" @click="favorites_menu=false" />
          </div>
          <p class="text-center mb-4">{{loc.favorites}}</p>

          <div v-for="item in $store.state.auth.user.favorite">
            <div class="flex px-2 pt-2 items-center">
              <img v-if="item.pictures.length" :src="item.pictures[0].image" class="w-10 h-10 rounded-full">
              <div class="w-10 h-10 rounded-full bg-gray-600" v-else />
              <div class="px-4 w-4/5">
                <p class="text-sm	">{{item.name}}</p>
                <p class="text-base-600">{{monetary(item.price, item.currency)}}</p>
              </div>
              <x-icon class="w-6 h-6 cursor-pointer  ml-auto" @click="$store.dispatch('auth/toggleFavorites', item.id)"/>
            </div>
          </div>
        </div>
      </div>
  </mobile-modal>

  <mobile-modal :open="cart_menu" @close="cart_menu = false">
      <div class="flex flex-col justify-between p-5 bg-gradient-to-r from-blur-1 via-blur-2 to-blur-1 rounded-xl shadow bg-blur z-40 in-menu">
        <div class="py-1">
          <div class="flex justify-end">
            <x-icon class="w-8 h-8" @click="cart_menu=false" />
          </div>
          <p class="text-center mb-4">{{loc.cart}}</p>

          <div v-for="item in $store.state.auth.user.cart">
            <div class="flex items-center px-2 pt-2">
              <img v-if="item.benefit.pictures.length" :src="item.benefit.pictures[0].image" class="w-10 h-10 rounded-full">
              <div class="w-10 h-10 rounded-full bg-gray-600" v-else />
              <div class="px-4">
                <p class="text-sm	">{{item.benefit.name}}</p>
                <p class="text-base-600">{{monetary(item.benefit.price * (100 - item.benefit.discount) / 100, item.benefit.currency)}}</p>
              </div>
              <x-icon class="w-6 h-6 cursor-pointer  ml-auto" @click.stop="$store.dispatch('auth/toggleCart', item.benefit.id)"/>
            </div>
          </div>
        </div>
        <div class="flex p-2">
          <button @click="$router.push('/cart')" type="button" class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-base-600 hover:bg-base-700 focus:outline-none ">{{loc.prev_cart}}</button>
          <p class="ml-auto">{{monetary($store.state.auth.user.cart.reduce((partialSum, a) => partialSum + Number(a.benefit.price) * (100 - a.benefit.discount) / 100, 0), $store.state.auth.user.currency)}}</p>
        </div>
      </div>
  </mobile-modal>

  <mobile-modal :open="notifications_menu" @close="notifications_menu = false">
    <div class="flex flex-col justify-between p-5 bg-gradient-to-r from-blur-1 via-blur-2 to-blur-1 rounded-xl shadow bg-blur z-50 in-menu">
      <div class="py-1">
        <div class="flex justify-end">
          <x-icon class="w-8 h-8" @click="notifications_menu=false" />
        </div>
        <p class="text-center mb-4">{{loc.notifications}}</p>

        <div v-for="item in $store.state.auth.user.notifications" class="divider-y">
          <div :class="`px-2 py-2 ${item.previewed ? 'hover:bg-gray-100' : 'bg-gray-200 hover:bg-gray-300'} cursor-pointer`">
            <p>{{item.text}}</p>
            <p class="text-xs text-base-500">{{item.date}}</p>
          </div>
        </div>
      </div>
    </div>
  </mobile-modal>
</template>

<script>
import { ref } from 'vue'
import newLogo from "@/components/ui/NewLogo";
import sidebar from "@/components/ui/sidebar";
import loader from "@/components/ui/loader";
import mobileModal from "@/components/ui/mobileModal";

import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import {
  CalendarIcon,
  ChartBarIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  MenuAlt2Icon,
  UsersIcon,
  XIcon,
    FilterIcon,
    MenuIcon,
    LogoutIcon,
  MenuAlt3Icon
} from '@heroicons/vue/outline'
import { SearchIcon, HeartIcon, ShoppingCartIcon, BellIcon } from '@heroicons/vue/solid'
import {mapActions, mapMutations, mapState} from "vuex";
import SuccessNotification from "@/components/ui/SuccessNotification";
import ErrorNotification from "@/components/ui/ErrorNotification";

export default {
  components: {
    ErrorNotification,
    SuccessNotification,
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    BellIcon,
    MenuAlt2Icon,
    SearchIcon,
    XIcon,
    newLogo,
    sidebar,
    loader,
    HeartIcon,
    ShoppingCartIcon,
    mobileModal,
    FilterIcon,
    MenuIcon,
    LogoutIcon,
    MenuAlt3Icon
  },
  setup() {
    const sidebarOpen = ref(false)
    const open = ref(false)

    return {
      sidebarOpen,
      open
    }
  },
  data() {
    return {
      sidebar: false,
      menu: false,
      notifications_menu: false,
      cart_menu: false,
      favorites_menu: false
    }
  },
  computed: {
    ...mapState('benefits', ['benefits_type']),
    total_budget() {
      this.$store.state.auth.user.base_balance + this.$store.state.auth.user.category_budgets.reduce((partialSum, a) => partialSum + Number(a.amount), 0)
    },
    total_budget_current() {
      this.$store.state.auth.user.balance + this.$store.state.auth.user.category_budgets.reduce((partialSum, a) => partialSum + Number(a.amount) - Number(a.spent), 0)
    }
  },
  methods: {
    ...mapActions('benefits', ['set_benefits_type']),
    ...mapActions('auth', ['fetchUser']),
    ...mapMutations('auth', ['logOut']),
    ...mapMutations('benefits', ['set_home_page']),
    async preview_notifications() {
      await this.axios.post('v2/preview-notifications/')
      await this.fetchUser()
    },
    set_benefits_type_check(value) {
      if (this.$route.path != '/') {
        this.$router.push('/')
      }
      this.set_benefits_type(value)
    },
    async change_language(lang) {
      await this.axios.patch('v2/me/',{'language': this.$store.state.auth.user.language == "SR" ? "EN" : "SR"})
      await this.fetchUser()
    },
    logout() {
      this.logOut()
      this.$router.push('/login')
    }
  },
  mounted() {
    // let content_width = document.getElementById('content-space').offsetWidth
    // document.getElementById('header-items-space').style.width = content_width + 'px'
    let buttons = document.getElementsByClassName('btn')
    console.log(buttons, "LLLL")
    for (let i = 0; i < buttons.length; i++) {
      console.log(buttons[i])
      buttons[i].addEventListener('click', (e) => {
        console.log(e, "LLL")
      })
    }
  }
}
</script>

<style>

.btn {
  color: #ffffff;
  transition: color 0.5s linear; /* vendorless fallback */
  -o-transition: color 0.5s linear; /* opera */
  -ms-transition: color 0.5s linear; /* IE 10 */
  -moz-transition: color 0.5s linear; /* Firefox */
  -webkit-transition: color 0.5s linear; /*safari and chrome */
}

.btn:hover {
  cursor: pointer;
  background: linear-gradient(
      rgba(0, 0, 0, 0.38),
      rgba(0, 0, 0, 0.47)
  ),
    /* bottom, image */
  url(https://images.unsplash.com/photo-1614030424754-24d0eebd46b2);
}

.container {
  width: 250px !important;
  text-align: center;
}

.progress2 {
  padding: 2px;
  border-radius: 30px;
  background: theme('colors.base.transparent_600');
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
}

.progress-bar2 {
  height: 7px;
  border-radius: 30px;
  background-image:
      linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
  transition: 0.4s linear;
  transition-property: width, background-color;
}

.progress-moved .progress-bar2 {
  width: 85%;
  background-color: theme('colors.base.500');
  animation: progressAnimation 6s;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 250px;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 0;

  /* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
  opacity: 0;
  transition: opacity 1s;
  /*text-shadow: 0 0 7px white, 0 0 7px white, 0 0 7px white;*/
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
</style>
