<template>
  <ul  role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 py-4" v-if="benefits && benefit_preview_type == 'grid'">
    <li
        v-for="benefit in benefits"
        class="h-64 col-span-1 bg-white rounded-lg shadow cursor-pointer hover:text-base-500 text-gray-700"
        @click="benefit.fixed? open_fixed(benefit) :$router.push(`/benefit/${benefit.id}`)"
    >

      <div class="w-full h-full flex justify-end flex-col items-start rounded-lg">
        <div :class="`flex items-start ${benefit.discount ? 'justify-between' : 'justify-start'} w-full`" style="height: 35%;">
          <div class="flex items-center space-x-3 p-2 w-5/6">
            <p class="text-sm font-bold text-ellipsis">{{benefit.name}}</p>
          </div>

          <div v-if="benefit.discount" class="w-1/6 bg-base-600 rounded-tr-lg text-white text-center font-semibold p-2 rounded-bl-xl">{{benefit.discount}}%</div>
        </div>

        <div class="p-1 bg-gray-300 w-full flex justify-between" style="height: 15%;" v-if="benefits_type!='fixed'">
          <p class="font-normal line-through" v-if="!benefit.fixed && benefit.price > 0 && benefit.discount">{{benefit.price_currency}}</p>
          <p class="font-semibold" v-if="!benefit.fixed && benefit.price > 0">{{benefit.price_discount_currency}}</p>
          <div v-else></div>
          <div class="flex w-1/3 justify-end text-gray-600">
            <heart-icon v-if="benefits_type!='fixed'" @click.stop="toggleFavorites(benefit.id)" :class="`hover:text-red-600 ${userFavoriteIds.includes(benefit.id) ? 'text-red-600': ''}`" />
            <shopping-cart-icon v-if="!benefit.fixed && !benefit.special_offer && benefit.price > 0" @click.stop="toggleCart(benefit.id)" :class="`hover:text-base-500 ${userCartIds.includes(benefit.id) ? 'text-base-500': ''}`" />
          </div>
        </div>

        <div :class="`rounded-b-lg w-full ${benefit.fixed ? 'h-2/3' : 'h-1/2'} bg-gray-400 bg-cover`" :style="`background-image: url('${getBaseImg(benefit.pictures)}'); background-position: center;`">

        </div>
      </div>
    </li>
  </ul>

  <ul  role="list" class="space-y-4" v-if="benefits && benefit_preview_type == 'list'">
    <li
        v-for="benefit in benefits"
        class="h-24 col-span-1 bg-white rounded-lg shadow cursor-pointer text-gray-700"
        @click="$router.push(`/benefit/${benefit.id}`)"
    >

      <div class="w-full h-full flex justify-start rounded-lg space-x-4">
        <div class="rounded-l-lg bg-cover w-1/6 h-full bg-gray-400" :style="`background-image: url('${getBaseImg(benefit.pictures)}'); background-size: contain; background-position: center center; background-repeat: no-repeat;`"/>
        <p class="w-64 text-md font-bold">{{benefit.name}}</p>
        <p class="w-64 text-xs my-2 font-bold cut-text text-gray-500 w-1/4"> {{extractContent(benefit.short_description)}} </p>

        <div class="ml-auto flex justify-between items-center w-1/3">
          <div class="text-base-500">
            <p class="font-normal line-through" v-if="!benefit.fixed && benefit.price > 0 && benefit.discount">{{benefit.price_currency}}</p>
            <p class="font-semibold" v-if="!benefit.fixed && benefit.price > 0">{{benefit.price_discount_currency}}</p>
          </div>
          <div class="flex">
            <heart-icon v-if="benefits_type!='fixed'" @click.stop="toggleFavorites(benefit.id)" :class="`hover:text-red-600 ${userFavoriteIds.includes(benefit.id) ? 'text-red-600': ''} w-6 h-6 text-gray-500`" />
            <shopping-cart-icon v-if="!benefit.fixed && !benefit.special_offer && benefit.price > 0" @click.stop="toggleCart(benefit.id)" :class="`hover:text-base-500 ${userCartIds.includes(benefit.id) ? 'text-base-500': ''} w-6 h-6 text-gray-500`" />
          </div>
          <div class="mb-auto bg-base-600 rounded-tr-xl rounded-bl-xl p-2 font-semibold text-white">
            {{benefit.discount}}%
          </div>
        </div>
      </div>
    </li>
  </ul>

  <fixed-benefit :open="fixed_benefit_open" @close="fixed_benefit_open = false" :benefit="fixed_benefit" />
</template>

<script>
import {ShoppingCartIcon, HeartIcon} from "@heroicons/vue/solid"
import {mapActions, mapGetters, mapState} from "vuex";
import fixedBenefit from "@/components/ui/FixedBenefit";

export default {
  name: "benefitList",
  data() {
    return {
      fixed_benefit: null,
      fixed_benefit_open: false
    }
  },
  props: ['benefits'],
  components: {
    ShoppingCartIcon,
    HeartIcon,
    fixedBenefit
  },
  computed: {
    ...mapState('benefits', ['benefits_type']),
    ...mapState('ui', ['benefit_preview_type']),
    ...mapGetters('auth', {
      userFavoriteIds: 'userFavoriteIds',
      userCartIds: 'userCartIds'
    })
  },
  methods: {
    ...mapActions('auth', {
      toggleFavorites: 'toggleFavorites',
      toggleCart: 'toggleCart'
    }),
    open_fixed(b) {
      console.log('asdasdasda')
      this.fixed_benefit = b
      this.fixed_benefit_open = true
    },
    close_fixed() {
      this.fixed_benefit_open = false
      console.log('asdlkalskd')
      this.fixed_benefit = null
    },
    extractContent(s) {
      var span = document.createElement('span');
      span.innerHTML = s;
      return span.textContent || span.innerText;
    }
  }
}
</script>

<style scoped>
.cut-text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>