import axios from "@/config/http"
import ct from "vue3-date-time-picker";

const state = () => ({
    categories: [],
    selected_category: null,
    sort: "",
    ordering: "+",
    search: "",
    selected_category_providers: [],
    location: ""
});

const mutations = {
    set_first_name(state, name) {
        state.categories[0].name = name
    },
    set_search(state, value) {
        state.search = value
    },
    set_location(state, value) {
        state.location = value
    },
    set_ordering(state, value) {
        state.ordering = value
    },
    set_categories(state, categories) {
        state.categories = [ ...categories ]
    },
    set_category(state, category) {
        if (state.selected_category != category) {
            state.selected_category = category
        } else {
            state.selected_category = null
        }
    },
    set_sort(state, value) {
        state.sort = value
    },
    set_category_providers(state, data) {
        state.selected_category_providers = data
    }
};

const actions = {
    async fetch_categories(context, url) {
        let {data} = await axios.get('v2/category/')
        context.commit('set_categories', data)
    },
    async fetch_category_providers(ctx, cat) {
        let {data} = await axios.get(`v2/category-providers/${cat}/?special=${ctx.getters['benefits_type'] == 'special' ? 'true' : 'false'}`)
        ctx.commit('set_category_providers', data)
    },
    set_location(ctx, value) {
        ctx.commit('set_location', value)
        window.scrollTo(0, 0);
        ctx.dispatch('benefits/fetchBenefits', null, {root: true})
    },
    set_ordering(ctx, value) {
        ctx.commit('set_ordering', value)
        window.scrollTo(0, 0);
        ctx.dispatch('benefits/fetchBenefits', null, {root: true})
    },
    set_search(ctx, value) {
        ctx.commit('set_search', value)
        window.scrollTo(0, 0);
        ctx.dispatch('benefits/fetchBenefits', null, {root: true})
    },
    select_sort(ctx, value) {
        ctx.commit('set_sort', value)
        window.scrollTo(0, 0);
        ctx.dispatch('benefits/fetchBenefits', null, {root: true})
    },
    select_category(ctx, category) {
        ctx.commit('set_category', category)
        ctx.commit('benefits/clear_selected_providers', null, {root: true})
        window.scrollTo(0, 0);
        ctx.dispatch('benefits/fetchBenefits', null, {root: true})
        if(category) {
            if (ctx.getters['benefits_type'] != 'special'){
                ctx.commit('benefits/set_benefits_type', 'flexible', {root: true})
            } else {
                ctx.commit('benefits/set_benefits_type', 'special', {root: true})
            }

            ctx.dispatch('fetch_category_providers', category)
        } else {
            ctx.commit('set_category_providers', [])
        }
    },
    select_category_by_position(ctx, position) {
        ctx.commit('benefits/clear_selected_providers', null, {root: true})

        console.log(ctx.state.categories, position, "KKSKSK")

        ctx.commit('set_category', ctx.state.categories[position].id)
        ctx.dispatch('fetch_category_providers', ctx.state.categories[position].id)
        window.scrollTo(0, 0);
        ctx.dispatch('benefits/fetchBenefits', null, {root: true})
        ctx.commit('benefits/set_benefits_type', 'flexible', {root: true})

    }
};
const getters = {
    benefits_type(state, getters, rootState, rootGetters) {
        return rootGetters['benefits/benefits_type']
    },
    search_string(state) {
        return state.search
    },
    location(state) {
        return state.location
    },
    search(state) {
        return state.search
    },
    sorting(state) {
        return state.sort
    },
    ordering(state) {
        return state.ordering
    },
    current_category_id(state) {
        return state.selected_category
    },
    selected_category(state) {
        if (state.categories.length && state.selected_category) {
            let cat = state.categories.find(e=>e.id == state.selected_category)

            if(!cat) {
                cat = state.categories.find(e=>e.children.some(el=>el.id==state.selected_category))
            }

            return cat

        }
        return null
    },
    current_category_object(state) {
        if (state.categories.length && state.selected_category) {
            return state.categories.find(e=>e.id == state.selected_category)
        }
        return null
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}