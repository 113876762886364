<template>
  <div
       class="sidebar fixed w-1/5 h-full pb-48 bg-white overflow-y-auto overflow-x-clip"
  >

    <div class="mt-5">
      <div
          class="flex flex-col pb-4 space-y-1 overflow-y-auto overflow-x-hidden"
      >
        <div v-for="item in $store.state.filters.categories"
             :key="item.name">
          <div
              @click="select_category(item.id)"
              :class="`flex flex-col justify-start space-x-1.5 px-1.5 cursor-pointer hover:text-base-600 ${item.id == current_category_id ? 'border-2 border-base-600 text-base-600' : 'text-gray-600'} hover:bg-white items-center`"
          >
            <div class="text-3xl">
              <i v-if="item.icon" :class="`fa fa-${item.icon} text-base-600`" aria-hidden="true"></i>
              <i v-else class="fa fa-arrows-h" aria-hidden="true"></i>
            </div>
            <p class="text-xs text-center px-2">{{ item.name }}</p>
          </div>
<!--          <div-->
<!--              class="bg-gradient-to-r from-transparent via-white to-gray-50 border border-white rounded-2xl mr-4 p-2 font-semibold space-y-2"-->
<!--              v-if="item.children.length && (item.id == current_category_id || item.children.find(e=>e.id == current_category_id)) "-->
<!--          >-->
<!--            <div v-for="sub in item.children">-->
<!--              <div-->
<!--                  @click="select_category(sub.id)"-->
<!--                  :class="`flex justify-start space-x-1.5 px-1.5 cursor-pointer ${sub.id == current_category_id ? 'bg-base-transparent_500 text-base-600' : 'text-gray-600'} hover:text-base-600 hover:bg-base-transparent_500 rounded-xl items-center mr-1`"-->
<!--              >-->
<!--                <p>{{ sub.name }}</p>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import newLogo from "@/components/ui/NewLogo";
import {ChevronLeftIcon, ChevronRightIcon, XIcon} from "@heroicons/vue/solid"
import {ArrowLeftIcon} from "@heroicons/vue/outline"
import gsap from "gsap";
import {mapActions, mapGetters} from "vuex";

export default {
  components: {
    newLogo,
    ChevronLeftIcon,
    ChevronRightIcon,
    XIcon
  },
  name: "sidebar",
  data() {
    return {
      open: false
    }
  },
  computed: {
    ...mapGetters('filters', ['current_category_id'])
  },
  watch: {
    open: function (val) {
      if (val) {
        let screen_w = window.screen.width
        let sidebar_w = 400

        if (screen_w < 400) {
          sidebar_w = screen_w - 30
        }

        gsap.to("#sidebar", {width: sidebar_w + 'px', ease: 'elastic', duration: 0.9})
        // gsap.to("#sidebar-icon", {scale: '1.2', ease: 'back', duration: 0.4})
      } else {
        gsap.to("#sidebar", {width: '85px', ease: 'back', duration: 0.4})
      }
    }
  },
  methods: {
    ...mapActions('filters', ['select_category'])
  },
  async mounted() {
    this.$store.dispatch('filters/fetch_categories')
  }
}
</script>

<style scoped>
#sidebar {
  width: 20%;
  /*width: calc(100% - 50px);*/
}

.sidebar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.sidebar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/*#categories-list {*/
/*  height: 90%;*/
/*}*/


/*
 *  STYLE 7
 */

#categories-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
  border-radius: 4px;
}

#categories-list::-webkit-scrollbar {
  width: 4px;
  background-color: #F5F5F5;
}

#categories-list::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-image: -webkit-gradient(linear,
  left bottom,
  left top,
  color-stop(0.44, theme('colors.base.500')),
  color-stop(0.72, theme('colors.base.600')),
  color-stop(0.86, theme('colors.base.700')));
}
</style>