import axios from "axios";
// Set config defaults when creating the instance
const instance = axios.create({
    baseURL: process.env.VUE_APP_ROOT_API
});

// Alter defaults after instance has been created
instance.defaults.headers.common['Authorization'] = "Token " + localStorage.getItem("token");
instance.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response.status == 401) {
        localStorage.removeItem('token')
        window.location.replace('/login')
    }
    return Promise.reject(error);
});
export default instance;